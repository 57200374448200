import React, {useState} from "react";
import {doTheActualLogin, Error} from "../util/api";
import {InputElement} from "../components/InputField";
import Button from "react-bootstrap/Button";
import {useLargeScreen} from "../util/screen";
import {WrapperComponent} from "../components/WrapperComponent";


const Login = () => {
  const [ssn, setSsn] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const isLargeScreen = useLargeScreen()

  return (
      <WrapperComponent isLoading={isLoading} error={error}>
        <div style={{
          display: "flex",
          justifyContent: "center"
        }}>
          <div style={{
            margin: 30,
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxWidth: isLargeScreen ? "300px" : "100vw"
          }}>
            <InputElement label={"SSN"} setValue={setSsn}/>
            <Button
                style={{
                  backgroundColor: "#007272",
                  marginTop: 30,
                }}
                variant="primary"
                onClick={async event => {
                  setIsLoading(true)
                  const res = await doTheActualLogin(ssn);
                  if (res.ok) {
                    window.location.href = res.value
                  } else {
                    setError(error)
                  }
                  setIsLoading(false)
                }}>
              Login
            </Button>
          </div>
        </div>
      </WrapperComponent>
  );
};

export default Login;
