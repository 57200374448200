import React from "react";
import Form from 'react-bootstrap/Form';
import {useMediaQuery} from "react-responsive";
import Button from "react-bootstrap/Button";


const InputElement = (props: { label: string, setValue: any }) => {

  const isLarge = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  return (
      <div style={{
        display: "flex",
        flexDirection: isLarge ? "row" : "column"
      }}>
        <Form.Label>{props.label}</Form.Label>
        <Form.Control
            onChange={event => props.setValue(event.target.value)}
            aria-describedby="passwordHelpBlock"
        />
      </div>
  )
};

const PaymentConfirmation = () => {
  return (
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: 30,
      }}>
        <p>Your payment was successful!</p>
        <Button
            style={{
              backgroundColor: "#007272"
            }}
            onClick={() => {
              window.location.href = window.location.origin
            }}
            variant="primary">Go home</Button>
      </div>
  );
};


export default PaymentConfirmation;
