import React, {useEffect, useState} from "react";
import {Error, fetchAccount, fetchTransactions} from "../util/api";
import {useParams} from "react-router-dom";
import {IAccount, ITransaction} from "../util/models";
import {WrapperComponent} from "../components/WrapperComponent";
import {useLargeScreen} from "../util/screen";


const InfoItem = (props: { label: string, value: any }) => {
  return <div>
    <p style={{
      marginBottom: 5,
      color: "darkgray",
      fontSize: "0.8em"
    }}>{props.label}</p>
    <p>{props.value}</p>
  </div>
}

const TransactionRow = (props: { transaction: ITransaction }) => {
  const transaction = props.transaction
  return (
      <div style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
        marginTop: 5,
        borderBottom: "1px solid #007272",
      }}>
        <div>
          <p style={{
            marginBottom: 5,
            color: "darkgray",
            fontSize: "0.8em"
          }}>{transaction.bookingDate}</p>
          <p style={{
            marginTop: 0,
            fontSize: "0.8em"
          }}>{transaction.additionalInformation}</p>
        </div>
        <p style={{
          fontSize: "0.9em"
        }}>{`${transaction.transactionAmount.amount}`}</p>
      </div>
  )
};

const AccountDetails = () => {
  const [account, setAccount] = useState<IAccount | undefined>(undefined);
  const [pending, setPending] = useState<ITransaction[]>([]);
  const [booked, setBooked] = useState<ITransaction[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>(undefined);

  let {accountId} = useParams();
  const isLargeScreen = useLargeScreen()


  useEffect(() => {
    const handler = async () => {
      setIsLoading(true)
      if (accountId) {
        const res = await fetchAccount(accountId, true)
        if (res.ok) {
          setAccount(res.value)
        } else {
          setError(res.error)
        }
      }
      const t = await fetchTransactions(accountId)
      if (t.ok) {
        setPending(t.value.transactions.pending)
        setBooked(t.value.transactions.booked)
      } else {
        setError(t.error)
      }
      setIsLoading(false)
    }
    handler()
  }, [])
  return (
      <WrapperComponent isLoading={isLoading} error={error}
                        raws={[{label: "Account", raw: account}]}>
        <div style={{
          margin: "0 auto",
          maxWidth: isLargeScreen ? "60vw" : "100vw",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}>

          <div style={{
            paddingLeft: 30,
            paddingRight: 30,
            marginTop: 30,
            borderBottom: "1px solid #007272",
          }}>
            <div style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "30px"
            }}>
              <h2>{account?.name}</h2>
            </div>
            <div style={{
              display: "flex",
              justifyContent: "space-between",
            }}>
              <InfoItem label={"Account number"} value={account?.bban}/>
              <InfoItem label={"Balance"}
                        value={account?.balances.find(b => b.balanceType === "authorised")?.balanceAmount.amount}/>
              <InfoItem label={"Currency"} value={account?.currency}/>
            </div>
          </div>
          <div style={{
            margin: 15
          }}>
            {pending.map(transaction => {
              return <TransactionRow key={transaction.transactionId} transaction={transaction}/>
            })}
            {booked.map(transaction => {
              return <TransactionRow key={transaction.transactionId} transaction={transaction}/>
            })}
          </div>
        </div>
      </WrapperComponent>
  );
};


export default AccountDetails;
