import React from "react";
import {Error} from "../util/api";
import {ThreeDots} from "react-loader-spinner";
import {getItem, Keys} from "../util/storage";

export const WrapperComponent = (props: { isLoading: boolean, error: Error | undefined, children: React.ReactElement, raws?: { raw: any, label: string }[] }): React.ReactElement => {
  const style = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  }

  if (props.isLoading) {
    return <div style={style}>
      <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#007272"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          visible={true}
      />
    </div>
  }

  if (props.error) {
    return <div style={{
      ...style,
      flexDirection: "column"
    }}>
      <h3>{`Error: ${props.error.code}`}</h3>
      <h3>{`Message: ${props.error.message}`}</h3>
    </div>
  }

  const devMode = getItem(Keys.DEV_MODE) === "true"

  return <>
    {props.children}
    {devMode && props.raws && props.raws.map(r => {
      return <div style={{
        borderTop: "1px solid #007272",
        backgroundColor: "gray",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 100,
      }}>
        <p>{r.label}</p>
        <pre>
          {JSON.stringify(r.raw, null, 2)}
        </pre>
      </div>
    })}
  </>
}
