import React from 'react';
import './App.css';
import {Route, Routes} from "react-router-dom";
import Home from "./screens/Home";
import Payment from "./screens/Payment";
import Login from "./screens/Login"
import PaymentConfirmation from "./screens/PaymentConfirmation";
import AccountDetails from "./screens/AccountDetails";
import {Navbar} from "./components/Navbar";
import {Footer} from "./components/Footer";

function App() {
  return (
      <div style={{}}>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/payments/:accountId" element={<Payment/>}/>
          <Route path="/payments/confirmation" element={<PaymentConfirmation/>}/>
          <Route path="/account/:accountId" element={<AccountDetails/>}/>
          <Route path="/login" element={<Login/>}/>
        </Routes>
        <Footer />
      </div>
  );
}


export default App;
