import React, {useEffect, useState} from "react";
import {doTheActualPayment, fetchAccount} from "../util/api";

import Button from 'react-bootstrap/Button';
import {useParams} from "react-router-dom";
import {InputElement} from "../components/InputField";


const Payment = () => {
  const [amount, setAmount] = useState<string>("");
  const [creditorAccount, setCreditorAccount] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [debtorAccount, setDebtorAccount] = useState<string>("");
  let {accountId} = useParams();

  useEffect(() => {
    const handler = async () => {
      if (accountId) {
        const res = await fetchAccount(accountId)
        if (res.ok) {
          setDebtorAccount(res.value.bban)
        }
      }
    }
    handler()
  }, [])
  return (
      <div>

        <div style={{
          margin: 30,
          display: "flex",
          flexDirection: "column"
        }}>
          <InputElement label={"Creditor account"} setValue={setCreditorAccount}/>
          <InputElement label={"Amount"} setValue={setAmount}/>
          <InputElement label={"Message"} setValue={setMessage}/>
          <Button
              style={{
                backgroundColor: "#007272",
                marginTop: 30,
              }}
              variant="primary"
              onClick={async event => {
                window.location.href = await doTheActualPayment(debtorAccount,
                    creditorAccount, amount, message);

              }}
          >Pay</Button>

        </div>
      </div>
  );
};


export default Payment;
