import {useMediaQuery} from "react-responsive";
import Form from "react-bootstrap/Form";
import React from "react";


export const InputElement = (props: { label: string, setValue: any }) => {

  const isLarge = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  return (
      <div style={{
        display: "flex",
        flexDirection: isLarge ? "row" : "column"
      }}>
        <Form.Label>{props.label}</Form.Label>
        <Form.Control
            onChange={event => props.setValue(event.target.value)}
            aria-describedby="passwordHelpBlock"
        />
      </div>
  )
};
