import React, {useEffect, useState} from "react";
import {Error, fetchAccountList} from "../util/api";
import {GiTakeMyMoney} from "react-icons/gi";
import {IAccount} from "../util/models";
import {WrapperComponent} from "../components/WrapperComponent";
import {useLargeScreen} from "../util/screen";


const Amount = (props: { account: IAccount }) => {
  return <div>
    <p>{`${props.account.balances.find(b => b.balanceType === "authorised")?.balanceAmount.amount} ${props.account.currency}`}</p>
  </div>
}

const PayButton = (props: { accountId: string }) => {
  return <a
      href={`/payments/${props.accountId}`}
      style={{
        marginLeft: 30,
        marginBottom: 10,
        backgroundColor: "#007272",
        borderRadius: 10,
        padding: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}>
    <GiTakeMyMoney size={30} color={"white"}/>
  </a>
};

const Home = () => {


  const [accounts, setAccounts] = useState<IAccount[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>(undefined);

  const isLargeScreen = useLargeScreen()

  useEffect(() => {
    if (localStorage.getItem("consentId") === null) {
      window.location.href = `${window.location.origin}/login`
    }
  }, [])

  useEffect(() => {
    const handler = async () => {
      setIsLoading(true)
      const res = await fetchAccountList()
      if (res.ok) {
        setAccounts(res.value)
      } else {
        setError(res.error)
      }
      setIsLoading(false)
    }
    handler()
  }, [])

  return (
      <WrapperComponent isLoading={isLoading} error={error}>
        <div style={{
          margin: "0 auto",
          maxWidth: isLargeScreen ? "60vw": "100vw"
        }}>
          {accounts.map(account => {
            return (
                <div
                    key={account.resourceId}
                    onClick={() => {
                      window.location.href = `${window.location.origin}/account/${account.resourceId}`
                    }}
                    style={{
                      cursor: "pointer",
                      marginTop: "1em",
                      marginBottom: 20,
                      marginLeft: 5,
                      marginRight: 5,
                      borderBottom: "1px solid #007272",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between"
                    }}>
                  <div>
                    <p style={{
                      color: "#007272"
                    }}>{account.name}</p>
                    <p style={{
                      color: "darkgray",
                      fontSize: "0.7em",
                    }}>{account.bban}</p>
                  </div>
                  <div style={{
                    display: "flex",
                    alignItems: "flex-end",
                  }}>
                    <Amount account={account}/>
                    <PayButton accountId={account.resourceId}/>
                  </div>
                </div>
            )
          })}

        </div>
      </WrapperComponent>
  );
};

export default Home;
