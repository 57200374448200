import React from "react";
import {useMediaQuery} from "react-responsive";


export const Navbar = () => {
  const isLarge = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  return <div
      style={{
        height: 50,
        display: "flex",
        justifyContent: "center",
        cursor: "pointer",
        alignItems: "center",
        borderBottom: "1px solid #007272",
        backgroundColor: "#007272"
      }}>
    <div style={{
      width: "33%"
    }}>
    </div>
    <div
        onClick={() => {
          window.location.href = window.location.origin
        }}
        style={{
          width: "33%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
      <h1 style={{
        textAlign: "center",
        fontSize: isLarge ? "inherit": "1em"
      }}>Troverdig bank</h1>
    </div>
    <div
        onClick={() => {
          window.location.href = `${window.location.origin}/login`
        }}
        style={{
          width: "33%",
          display: "flex",
          justifyContent: "flex-end"
        }}>
      <p>{localStorage.getItem("ssn")}</p>
    </div>
  </div>
}
