export enum Keys {
  CONSENT_ID = "consentId",
  DEV_MODE = "devMode"
}

export const getItem = (key: Keys): undefined | string => {
  const value = localStorage.getItem(key)
  if (value) {
    return value
  }
  return undefined
}

export const setItem = (key: Keys, value: string) => {
  localStorage.setItem(key, value)
}
