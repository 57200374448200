import {IAccount, IAccountBalances, IAccountsResponse, ITransactionResponse} from "./models";
import {getItem, Keys} from "./storage";

const baseUrl = "https://tpp-app.dev-tm.dob.tech-03.net"


export enum ErrorCode {
  GENERIC = "GENERIC",
  CONSENT_ID_MISSING = "CONSENT_ID_MISSING",
  ACCOUNT_NOT_FOUND = "ACCOUNT_NOT_FOUND"
}

export interface Error {
  code: ErrorCode,
  message: string
}

export type Result<T, E = Error> =
    | { ok: true; value: T }
    | { ok: false; error: E };


export const fetchTransactions = async (id: string | undefined): Promise<Result<ITransactionResponse>> => {
  const url = `${baseUrl}/accounts/${id}/transactions`
  const consentId = getItem(Keys.CONSENT_ID)
  if (!consentId) {
    return {
      ok: false,
      error: {
        code: ErrorCode.CONSENT_ID_MISSING,
        message: "Consent id missing"
      }
    }
  }
  const res = await fetch(url,
      {
        method: "GET",
        headers: {
          "consent-id": consentId,
          "x-request-id": "0-0-0-0",
        }
      }
  )

  if (!res.ok) {
    return resolveError(res)
  }
  const data: ITransactionResponse = await res.json()
  return {
    ok: true,
    value: data
  }
}

export const fetchAccount = async (id: string, withBalance: boolean = false): Promise<Result<IAccount>> => {
  const url = `${baseUrl}/accounts/${id}`
  const consentId = getItem(Keys.CONSENT_ID)
  if (!consentId) {
    return {
      ok: false,
      error: {
        code: ErrorCode.CONSENT_ID_MISSING,
        message: "Consent id missing"
      }
    }
  }
  const res = await fetch(url,
      {
        method: "GET",
        headers: {
          "consent-id": consentId,
          "x-request-id": "0-0-0-0",
        }
      }
  )

  if (!res.ok) {
    return resolveError(res)
  }
  const data: IAccount = await res.json()
  if (withBalance) {
    const balanceRes = await fetchAccountBalances(id)
    if (balanceRes.ok) {
      return {
        ok: true,
        value: {
          ...data,
          balances: balanceRes.value.balances
        }
      }
    }
  }
  return {
    ok: true,
    value: data
  }
}

export const fetchAccountBalances = async (id: string): Promise<Result<IAccountBalances>> => {
  const url = `${baseUrl}/accounts/${id}/balances`
  const consentId = getItem(Keys.CONSENT_ID)
  if (!consentId) {
    return {
      ok: false,
      error: {
        code: ErrorCode.CONSENT_ID_MISSING,
        message: "Consent id missing"
      }
    }
  }
  const res = await fetch(url,
      {
        method: "GET",
        headers: {
          "consent-id": consentId,
          "x-request-id": "0-0-0-0",
        }
      }
  )

  if (!res.ok) {
    return resolveError(res)
  }
  const data: IAccountBalances = await res.json()
  return {
    ok: true,
    value: data
  }
}

export const fetchAccountList = async (): Promise<Result<IAccount[]>> => {
  const url = `${baseUrl}/accounts`
  const consentId = getItem(Keys.CONSENT_ID)
  if (!consentId) {
    return {
      ok: false,
      error: {
        code: ErrorCode.CONSENT_ID_MISSING,
        message: "Consent id missing"
      }
    }
  }
  const res = await fetch(url,
      {
        method: "GET",
        headers: {
          "consent-id": consentId,
          "x-request-id": "0-0-0-0",
        }
      }
  )
  if (!res.ok) {
    return resolveError(res)
  }
  const data: IAccountsResponse = await res.json()

  const promises = data.accounts.map(async a => {
    const b = await fetchAccountBalances(a.resourceId)
    if (b.ok) {
      return {
        ...a,
        balances: b.value.balances
      }
    }
    return a

  })

  const resAll = await Promise.all(promises)
  return {
    ok: true,
    value: resAll
  }
}

const resolveError = async (res: Response): Promise<{ ok: false, error: Error }> => {
  try {
    const data = await res.json()
    if (data) {
      const tppMessages = data.tppMessages
      const tppMessage = tppMessages.find(() => true)
      if (tppMessage) {
        return {
          ok: false,
          error: {
            code: tppMessage.code,
            message: tppMessage.text
          }
        }
      }
    }
  } catch (e) {
    console.log(e)
  }

  return {
    ok: false,
    error: {
      code: ErrorCode.GENERIC,
      message: "Generic error message. Issues parsing from backend."
    }
  }

}


export const doTheActualLogin = async (ssn: string): Promise<Result<string>> => {
  const url = `${baseUrl}/consents`
  const res = await fetch(url,
      {
        method: "POST",
        headers: {
          "x-request-id": "0-0-0-0",
          "psu-id": ssn,
          "tpp-redirect-uri": window.location.origin
        }
      }
  )
  if (!res.ok) {
    return resolveError(res)
  }
  const data = await res.json()
  const consentId = data.consentId
  const redirectUri = data._links.scaRedirect.href
  localStorage.setItem("consentId", consentId)
  localStorage.setItem("ssn", ssn)
  return {
    ok: true,
    value: redirectUri
  }
}


export const doTheActualPayment = async (debtorAccount: string, creditorAccount: string, amount: string, message: string): Promise<string> => {
  return `${window.location.origin}/payments/confirmation`
}
