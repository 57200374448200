import React from "react";
import {getItem, Keys, setItem} from "../util/storage";
import Button from "react-bootstrap/Button";


export const Footer = () => {

  const isDev = getItem(Keys.DEV_MODE) === "true"
  return <div
      style={{
        position: "fixed",
        left: 0,
        bottom: 0,
        width: "100vw",
        backgroundColor: "gray",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        borderTop: "1px solid #007272"
      }}>
    <div style={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      margin: 10,
    }}>
      <p style={{
        marginRight: 30,
      }}>{`Dev mode ${isDev ? "enabled" : "not enabled"}`}</p>
      <Button
          style={{
            backgroundColor: "#007272",
          }}
          variant="primary"
          onClick={() => {
            setItem(Keys.DEV_MODE, isDev ? "false" : "true")
            window.location.reload()
          }}
      >{isDev ? "Disable" : "Enable"}</Button>
    </div>
  </div>
}
